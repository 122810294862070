<template>
    <styled-interface
        class="playbook"
        hide-toolbar
        no-padding
        @scrolled="onScroll">
        <playbooks-modal
            v-model="playbooksModalActive"
            :playbook="selectedPlaybook"
            :loading="isLoading"
            @select="onPlaybookSelection"
            @show-slideout="openSlideout" />
        <playbooks-slideout
            v-model="showSlideout"
            :playbook="selectedPlaybook"
            :facebook-selected="isFacebookSelected"
            :tiktok-selected="isTiktokSelected"
            :snapchat-selected="isSnapchatSelected"
            :pinterest-selected="isPinterestSelected" />
        <dealer-product-update-dialog
            v-model="productDialogActive"
            :dealer="currentDealer"
            :product-ids="selectedPlay ? [ selectedPlay.product_id ] : []"
            :play-id="selectedPlay ? selectedPlay.id : null" />

        <playbook-preview-panel
            v-model="playbookPreviewPanelOpen"
            :playbook="playbookToPreview" />


        <!-- Exit dialog start -->
        <styled-dialog
            v-model="exitDialogOpen"
            :width="600">
            <template #heading>
                <h3>Are you sure you want to exit?</h3>
            </template>

            <p class="mb-5">
                Since you've selected an advertising product
                we highly recommend selecting a playbook to deploy now.
                If you're not ready to advertise you can change
                your subscription on the dealer page. If you do exit
                you can get back to this page from the navigation
                under "Manage Playbooks". Are you sure you
                want to exit?
            </p>

            <div class="text-xs-center mb-4">
                <styled-button
                    @click="$router.push({
                        name: 'dealer'
                    })">
                    Yes, Exit to Dashboard
                </styled-button>
            </div>

            <a
                href="#"
                class="red--text"
                @click.prevent="exitDialogOpen = false">
                Cancel
            </a>
        </styled-dialog>
        <!-- Exit dialog end -->


        <onboarding-header
            v-if="!!$route.query.onboarding"
            :dealer="currentDealer"
            :products="dealerProducts"
            show-product
            show-play
            @complete="onProductUpdateComplete()">
            <template #actions>
                <action-button
                    icon="indicator-error"
                    position="left"
                    light
                    normal-text
                    @click="exitDialogOpen = true">
                    Exit
                </action-button>
            </template>
        </onboarding-header>


        <div
            v-if="loading"
            class="loading-container">
            <loader />
        </div>

        <template v-else>
            <div
                class="play-header"
                :class="{
                    'play-header-onboarding': !!$route.query.onboarding
                }">
                <h2>Playbooks</h2>
                <p v-if="!!$route.query.onboarding">
                    Since you've selected an advertising product let's get you setup with
                    some playbooks so you can advertise. Browse our extensive, and always
                    expanding, list on playbooks below to find one that best suits your clients'
                    needs and click "Deploy" when you're ready to continue!
                </p>
            </div>


            <div class="play-carousel">
                <h3>Featured</h3>
                <div v-if="featuredIsLoading">
                    <loader />
                </div>

                <div v-else>
                    <carousel
                        v-if="hasVerticalPlaybooks && featuredPlaybooks?.length"
                        :per-page="2"
                        :mouse-drag="true"
                        :navigation-enabled="true"
                        :pagination-enabled="false"
                        navigation-next-label="More"
                        navigation-prev-label="Back">
                        <slide
                            v-for="playbook in featuredPlaybooks"
                            :key="playbook.id"
                            class="slide mr-4 mt-2">
                            <playbook-card
                                :value="playbook"
                                :loading="loadingPlaybook == playbook.id"
                                @select="onPlaybookSelection"
                                @preview="onPlaybookPreview" />
                        </slide>
                    </carousel>
                </div>
            </div>

            <v-alert
                :value="error"
                class="mb-4">
                {{ error }}
            </v-alert>

            <div class="play-catalog layout">
                <div class="flex md3 xs12">
                    <div class="play-filters">
                        <div class="filter-wrapper">
                            <playbook-filter
                                v-model="selectedChannels"
                                :items="channelOptions"
                                label="Channel"
                                @input="filterPlaybooks" />
                        </div>
                        <div class="filter-wrapper">
                            <playbook-filter
                                v-model="selectedType"
                                :items="typeOptions"
                                label="Type"
                                @input="filterPlaybooks" />
                        </div>
                        <div class="filter-wrapper">
                            <playbook-filter
                                v-model="selectedBrand"
                                :items="brandOptions"
                                label="Brand"
                                show-search
                                @input="filterPlaybooks" />
                        </div>
                        <div class="filter-wrapper">
                            <playbook-filter
                                v-model="selectedFocus"
                                :items="focusOptions"
                                label="Focus"
                                show-search
                                @input="filterPlaybooks" />
                        </div>
                    </div>
                </div>


                <div
                    class="play-results flex md9 xs12"
                    :class="{
                        'no-results': noResults
                    }">
                    <v-expansion-panel
                        :value="controlPanel"
                        expand
                        class="expansion-container">
                        <v-expansion-panel-content
                            @input="handleClick">
                            <template
                                #header>
                                <div>
                                    Custom Inventory Ad Deployments are available in our new Inventory Ad Launcher!
                                </div>
                            </template>
                            <template #actions>
                                {{ buttonText }}
                            </template>
                            <v-card>
                                In our ongoing effort to provide better service across our Omni-Channel lineup
                                we have reimagined the way inventory ads are deployed to ultimately give you control
                                prior
                                to deployment deployment with our new Inventory Ad launcher.
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <div class="play-controls">
                        <div class="play-controls-search">
                            <v-text-field
                                v-model="searchTerms"
                                label="Search"
                                placeholder="Enter terms..."
                                class="styled-field" />
                        </div>
                        <div v-if="!isTablet">
                            <toggle-view v-model="view" />
                        </div>
                    </div>

                    <div v-if="noResults">
                        <no-results />
                    </div>

                    <div>
                        <!-- List view start -->
                        <div
                            v-if="view == 'list'"
                            class="">
                            <playbook-list
                                v-for="playbook in playbooks"
                                :key="playbook.id"
                                :value="playbook"
                                :loading="loadingPlaybook == playbook.id"
                                class="mb-4"
                                @select="onPlaybookSelection"
                                @preview="onPlaybookPreview" />

                            <div
                                v-if="loadingMore"
                                class="loading-more-container">
                                <loader />
                            </div>

                            <div
                                v-if="!loadingMore && !hasNextPage && playbooks?.length > 0"
                                class="layout get-in-touch-row">
                                <div class="flex xs9 offset-xs3 layout align-center">
                                    <div class="flex text-xs-center">
                                        <h2 class="mb-3 font-weight-bold">
                                            Got a playbook strategy that you’d like us to build?
                                        </h2>
                                        <styled-button
                                            class="get-in-touch-button"
                                            @click="onRequestNewPlaybook">
                                            LET'S CHAT
                                        </styled-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- List view end -->

                        <!-- Grid view start -->
                        <div
                            v-else-if="view == 'grid'"
                            class="play-results-grid">
                            <playbook-grid
                                v-for="playbook in playbooks"
                                :key="playbook.id"
                                :value="playbook"
                                :loading="loadingPlaybook == playbook.id"
                                @select="onPlaybookSelection"
                                @preview="onPlaybookPreview" />

                            <div
                                v-if="loadingMore"
                                class="loading-more-container">
                                <loader />
                            </div>

                            <div
                                v-if="!loadingMore && !hasNextPage && playbooks?.length > 0"
                                class="play-callout-grid">
                                <div class="">
                                    <div class="wrap-content fill-height gray-icons">
                                        <h1 class="primary--text text-center py-4 px-3 font-weight-bold">
                                            Got a playbook strategy that you’d like us to build?
                                        </h1>
                                        <div class="px-3 form-button text-xs-center">
                                            <v-btn
                                                flat
                                                large
                                                color="white"
                                                @click.native="onRequestNewPlaybook">
                                                Let's Chat
                                            </v-btn>
                                        </div>
                                        <div class="">
                                            <v-img
                                                src="/img/plays/hero/get-in-touch.png"
                                                height="400px" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Grid view end -->
                    </div>
                </div>
            </div>
        </template>
    </styled-interface>
</template>

<script>
import { PRODUCT_ADVERTISING_PLUS } from '@/helpers/globals';
import { mapState, mapGetters, mapActions } from 'vuex';
import { debounce } from 'lodash';
import ActionButton from '@/components/globals/ActionButton';
import StyledDialog from '@/components/globals/StyledDialog';
import OnboardingHeader from '@/components/globals/OnboardingHeader';
import Loader from '@/components/globals/Loader';
import ToggleView from '@/components/globals/ToggleView';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledButton from '@/components/globals/StyledButton';
import DealerProductUpdateDialog from '@/components/globals/DealerProductUpdateDialog';
import PlaybooksModal from '@/components/playbooks/PlaybooksModal';
import PlaybooksSlideout from '@/components/playbooks/PlaybooksSlideout';
import PlaybookPreviewPanel from './PlaybookPreviewPanel';
import PlaybookGrid from './PlaybookGrid';
import PlaybookList from './PlaybookList';
import PlaybookCard from './PlaybookCard';
import PlaybookFilter from './PlaybookFilter';
import NoResults from './NoResults';
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'Playbooks',
    title: 'Playbooks',
    components: {
        NoResults,
        PlaybookFilter,
        ActionButton,
        StyledDialog,
        OnboardingHeader,
        DealerProductUpdateDialog,
        PlaybooksModal,
        PlaybooksSlideout,
        StyledInterface,
        PlaybookGrid,
        PlaybookList,
        PlaybookCard,
        StyledButton,
        Loader,
        ToggleView,
        Carousel,
        Slide,
        PlaybookPreviewPanel
    },
    beforeRouteEnter(_, from, next) {
        if (from.name === 'onboarding-complete') {
            next(vm => {
                vm.$router.go();
            });
        } else {
            next();
        }
    },
    data() {
        return {
            exitDialogOpen: false,
            channelOptions: [],
            selectedChannels: [],
            brandOptions: [],
            selectedBrand: [],
            focusOptions: [],
            selectedFocus: [],
            selectedPlaybook: {},
            typeOptions: [],
            selectedType: [],
            isTablet: false,
            isLoading: false,
            view: 'grid',
            dealerPlays: [],
            selectedPlay: null,
            loading: true,
            loadingPlaybook: null,
            productDialogActive: false,
            productAgreementVerified: false,
            loadingProductRelationships: false,
            error: null,
            hasInit: false,
            searchTerms: '',
            playbooksRaw: [],
            playbookPreviewPanelOpen: false,
            playbookToPreview: null,
            playbooksModalActive: false,
            showSlideout: false,
            isTiktokSelected: false,
            isSnapchatSelected: false,
            isFacebookSelected: false,
            isPinterestSelected: false,
            expandPanel: false,
            isPanelExpanded: false,
            pageSize: 9,
            filters: {
                marketing_assets: 'must_not_null'
            },
            page: 1,
            paginationMeta: {},
            sidebarFilterItems: {},
            featuredPlaybooks: [],
            hasVerticalPlaybooks: false,
            focusOptionKeyword: 'marketing_assets.focus.keyword',
            channelsOptionKeyword: 'marketing_assets.channels.keyword',
            typesOptionKeyword: 'marketing_assets.types.keyword',
            brandsOptionKeyword: 'marketing_assets.brands.keyword',
            defaultAggregations: [],
            needsNewAggregations: true,
            sortAggregations: 'asc',
            featuredIsLoading: true,
            loadingMore: false,
            hasNextPage: true,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId
        }),
        ...mapGetters([
            'onboardingIneligibleProducts',
            'onboardingIneligibleProductConflict',
            'activeDealerOnboardings',
            'dealerProducts',
            'dealerProductIds',
            'dealerHasAdProduct'
        ]),
        noResults() {
            return !this.playbooks?.length;
        },
        dealerHasAdsPlus() {
            return this.dealerProductIds.includes(PRODUCT_ADVERTISING_PLUS);
        },
        playbooks() {
            return this.playbooksRaw.map(this.formatPlaybook);
        },
        buttonText() {
            return this.expandPanel || this.isPanelExpanded ? 'hide' : 'show';
        },
        controlPanel() {
            return [this.expandPanel];
        },
    },
    watch: {
        isTablet() {
            if (this.view == 'list') {
                this.view == 'grid';
            }
        },
        searchTerms: {
            handler: debounce(function() {
                this.page = 1;
                this.needsNewAggregations = true;
                this.getPlaybooks();
            }, 300),
            immediate: true,
        }
    },
    mounted() {
        this.defaultAggregations = [
            this.focusOptionKeyword,
            this.channelsOptionKeyword,
            this.typesOptionKeyword,
            this.brandsOptionKeyword
        ];
    },
    async activated() {
        await this.init();

        this.onResize = debounce(() => {
            this.handleWindowSize();
        }, 300);
        window.addEventListener('resize', this.onResize.bind(this));
    },
    deactivated() {
        window.removeEventListener('resize', this.onResize.bind(this));
    },
    methods: {
        ...mapActions([
            'updateCurrentDealer'
        ]),
        onPlaybookPreview(playbook) {
            this.playbookToPreview = playbook;
            this.playbookPreviewPanelOpen = true;
        },
        async init() {
            this.loading = true;
            await this.updateCurrentDealer();

            const playbooksPromises = [
                await this.getDealerPlays(),
            ];

            if (! this.playbooksRaw?.length) {
                playbooksPromises.push(await this.getPlaybooks());
            }

            await Promise.all(playbooksPromises);

            this.loading = false;
            this.hasInit = true;

            if (! this.featuredPlaybooks?.length) {
                await this.getFeaturedPlaybooks();
            }

            this.featuredIsLoading = false;
        },
        onRequestNewPlaybook() {
            this.$store.dispatch('createTicket', {
                subject: 'New Playbook Request',
                type: 'Other'
            });
        },
        handleWindowSize() {
            this.isTablet = (window.innerWidth < 960);
        },
        async getPlaybooks() {
            try {
                const response = await this.$apiRepository.getPlaybooks(
                    this.searchTerms,
                    this.filters,
                    this.pageSize,
                    this.page,
                    this.defaultAggregations,
                    this.sortAggregations
                );

                this.paginationMeta = response.data.meta;

                if (response.data.aggregations && this.needsNewAggregations) {
                    this.sidebarFilterItems = response.data.aggregations;
                    this.brandOptions = (this.sidebarFilterItems[this.brandsOptionKeyword]).map(item => item.key);
                    this.focusOptions = (this.sidebarFilterItems[this.focusOptionKeyword]).map(item => item.key);
                    this.channelOptions = (this.sidebarFilterItems[this.channelsOptionKeyword]).map(item => item.key);
                    this.typeOptions = (this.sidebarFilterItems[this.typesOptionKeyword]).map(item => item.key);
                }

                this.playbooksRaw = response.data.data;

                this.hasNextPage = this.page < this.paginationMeta.last_page;
            } catch (error) {
                console.error('Error loading dealer plays', error);
                this.error = 'Error loading plays';
            }
        },
        async getDealerPlays() {
            try {
                const response = await this.$apiRepository.getDealerPlays(
                    this.currentDealerId
                );
                this.dealerPlays = response.data.data;
            } catch (error) {
                console.error('Error loading dealer plays', error);
                this.error = 'Error loading dealer plays';
            }
        },
        async onPlaybookSelection(playbook, activationFromModal) {
            this.selectedPlaybook = playbook;
            this.selectedPlay = playbook.plays?.[0];

            if (activationFromModal) {
                // If the play isn't valid we need the user to add
                // the appropriate product then continue
                if (!playbook.valid) {
                    this.productDialogActive = true;
                    return;
                }

                this.loadingPlaybook = playbook.id;
                this.isLoading = true;

                // If the play has an existing onboarding go to it
                if (playbook.onboarding) {
                    await this.$router.pushAsync({
                        name: 'dealer-onboarding',
                        params: {
                            onboarding_id: playbook.onboarding
                        }
                    });
                }
                // Otherwise let's start a new onboarding for the play
                else {
                    // If onboarding passed product IDs in the URL use them, otherwise fall
                    // back to the play's product ID
                    const productIds = this.$route.query?.product_ids || [playbook.plays?.[0]?.product_id];

                    await this.$store.dispatch('startNewOnboarding', {
                        productIds,
                        dealerId: this.currentDealerId,
                        playId: playbook.plays?.[0]?.id
                    });
                }
            } else {
                this.playbooksModalActive = true;
            }

            this.isLoading = false;
            this.loadingPlaybook = null;
            this.selectedPlay = null;
        },
        openSlideout(channels) {
            channels.has('facebook') ? this.isFacebookSelected = true : this.isFacebookSelected = false;
            channels.has('snapchat') ? this.isSnapchatSelected = true : this.isSnapchatSelected = false;
            channels.has('tiktok') ? this.isTiktokSelected = true : this.isTiktokSelected = false;
            channels.has('pinterest') ? this.isPinterestSelected = true : this.isPinterestSelected = false;
            this.showSlideout = true;
        },
        onProductUpdateComplete() {
            this.selectedPlay = null;
        },
        handleClick() {
            this.isPanelExpanded = !this.isPanelExpanded;
        },
        async getFeaturedPlaybooks() {
            try {
                const filters = {
                    'marketing_assets': 'must_not_null',
                    'marketing_assets.featured': 'true',
                };

                const response = await this.$apiRepository.getPlaybooks(
                    '',
                    filters,
                    20,
                    1
                );

                this.featuredPlaybooks = response.data.data.map(this.formatPlaybook);

                if (this.hasVerticalFeaturedPlaybooks()) {
                    this.hasVerticalPlaybooks = true;
                }
            } catch (error) {
                console.error('Error loading dealer plays', error);
                this.error = 'Error loading plays';
            }
        },
        hasVerticalFeaturedPlaybooks() {
            return this.featuredPlaybooks.some(playbook => {
                if (!playbook.marketing_assets?.verticals?.length) {
                    return true;
                }

                return playbook.marketing_assets?.verticals?.includes(this.currentDealer.vertical_id);
            });
        },
        async filterPlaybooks() {
            try {
                if (this.loadingMore) return;

                this.loadingMore = true;

                this.page = 1;

                this.filters = {
                    'marketing_assets': 'must_not_null',
                    'marketing_assets.channels.keyword': this.selectedChannels,
                    'marketing_assets.brands.keyword': this.selectedBrand,
                    'marketing_assets.focus.keyword': this.selectedFocus,
                    'marketing_assets.types.keyword': this.selectedType,
                };

                this.needsNewAggregations = false;

                const response = await this.$apiRepository.getPlaybooks(
                    this.searchTerms,
                    this.filters,
                    this.pageSize,
                    this.page,
                    []
                );
                this.playbooksRaw = response.data.data;

                await this.$nextTick();

                this.paginationMeta = response.data.meta;

                this.hasNextPage = this.page < this.paginationMeta.last_page;
            } catch (error) {
                console.error('Error loading dealer plays', error);
                this.error = 'Error loading plays';
            } finally {
                this.loadingMore = false;
            }
        },
        checkPlaybookStatus(playbook) {
            if (playbook.plays?.[0]?.product_id == PRODUCT_ADVERTISING_PLUS) {
                return this.dealerHasAdsPlus;
            }
            return this.dealerHasAdProduct;
        },
        formatPlaybook(playbook) {
            playbook.onboarding = false;
            playbook.deployed = false;
            playbook.error = null;
            playbook.valid = this.checkPlaybookStatus(playbook);

            const existingOnboarding = this.activeDealerOnboardings.find(onboarding => onboarding.play_id === playbook.plays?.[0]?.id);
            if (existingOnboarding) {
                playbook.onboarding = existingOnboarding.id;
            } else if (this.dealerPlays.some(dealerPlay => dealerPlay.play_id === playbook.plays?.[0]?.id)) {
                playbook.deployed = true;
                playbook.error = 'Currently Deployed';
            }

            return playbook;
        },
        async onScroll(event) {
            const container = event.target;

            if (container.scrollTop + container.clientHeight >= container.scrollHeight && !this.loadingMore && this.hasNextPage) {
                await this.loadMorePlaybooks();
            }
        },
        async loadMorePlaybooks() {
            if (this.loadingMore) return;

            this.loadingMore = true;
            this.page += 1;

            try {
                const response = await this.$apiRepository.getPlaybooks(
                    this.searchTerms,
                    this.filters,
                    this.pageSize,
                    this.page,
                    this.defaultAggregations,
                    this.sortAggregations
                );

                const newPlaybooks = response.data.data;

                this.playbooksRaw = [...this.playbooksRaw, ...newPlaybooks];

                await this.$nextTick();

                this.paginationMeta = response.data.meta;

                this.hasNextPage = this.page < this.paginationMeta.last_page;
            } catch (error) {
                console.error('Error loading more playbooks', error);
                this.error = 'Error loading more playbooks';
            } finally {
                this.loadingMore = false;
            }
        }
    },
};

</script>

<style lang="scss" scoped>

.playbook {
    overflow-y: auto;
    height: 100vh;
}

.filters {
    margin-left: auto;
    display: flex;
    align-items: center;

    & > * {
        margin-right: 1.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.filter-wrapper {
    margin-bottom: 2rem;
}

.play-header {
    margin: 3rem 4rem 1rem;
}

.play-header-onboarding {
    margin: 8rem 4rem 4rem;
}

.play-catalog {
    margin: 4rem;

    .play-results-grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }
}

.play-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.play-controls-search {
    min-width: 400px;
}

.play-filters {
    margin-right: 3rem;
    padding-right: 3rem;
    border-right: 1px solid $alabaster-dark;
}

.play-callout-grid {
    display: flex;
    width: calc(33.333% - 30px);
    margin: 15px;
}

.get-in-touch-row {
    box-shadow: 0 0 10px rgba($black, 0.1);
    background: $white;
    min-height: 190px;
    background-image: url('/img/plays/hero/get-in-touch-full.png');
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;

    h2 {
        font-size: 1.8rem;
    }
}

.get-in-touch-button {
    font-size: 18px;
    font-weight: bold;
}

.play-carousel {
    background: #DEE1F0;
    padding: 2rem 0;
    overflow-x: hidden;

    & > h3 {
        margin: 0 4rem 1rem;
    }
}

.more {
    text-align: right;
    // padding-top:13px;
    padding-right: 15px;

    a {
        color: #2693c9;
    }
}
</style>

<style lang="scss">
.play-carousel {
    .VueCarousel {
        margin: 0 20rem 0 4rem;

        .VueCarousel-wrapper {
            overflow: visible;
        }

        .VueCarousel-inner {
            margin-bottom: 2rem;
        }

        .VueCarousel-navigation {
            position: relative;
            padding-top: 20px;
            margin-right: -16rem;

            button {
                position: absolute;
                bottom: 0;
                color: #2693c9 !important;
                height: 35px;
                transition: opacity 0.3s ease-in-out;
            }

            .VueCarousel-navigation--disabled {
                opacity: 0;
                color: #000 !important;
                filter: invert(0%) sepia(94%) saturate(22%) hue-rotate(49deg) brightness(102%) contrast(106%);
            }

            .VueCarousel-navigation-prev {
                left: 70px !important;
            }

            .VueCarousel-navigation-prev:before {
                content: url('/img/chevron-left.svg');
                padding-right: 10px;
                position: absolute;
                left: -20px;
                filter: invert(58%) sepia(79%) saturate(653%) hue-rotate(175deg) brightness(107%) contrast(94%);
            }

            .VueCarousel-navigation-next:after {
                content: url('/img/chevron-right.svg');
                padding-left: 10px;
                position: absolute;
                right: -20px;
                filter: invert(58%) sepia(79%) saturate(653%) hue-rotate(175deg) brightness(107%) contrast(94%);
            }

            .VueCarousel-navigation-next {
                right: 70px !important;
            }
        }

        .VueCarousel-dot--active {
            background-color: #2693c9 !important;
        }
    }
}

.loading-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expansion-container {
    margin-bottom: 20px;
    border: 2px solid #90d942;

    .v-expansion-panel__body {
        padding: 5px 25px;
        background: #f3ffe6;

        .v-card {
            background: #f3ffe6;
        }
    }

    .v-expansion-panel__header {
        background: #f3ffe6;
        color: #96c907;
        font-weight: bold;
    }

    .v-expansion-panel__header__icon {
        color: #96c907;
        text-decoration: underline;
        margin-left: 3px;
        font-weight: normal;
    }
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    position: relative;

    .controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .currently-viewing,
        .prev-next {
            display: inline-block;
            margin: 0;
            margin-left: 60px;
        }
        .prev,
        .next {
            padding: 0 30px;
            opacity: 0.25;
            cursor: not-allowed;
            pointer-events: none;
            &.active{
                pointer-events: all;
                cursor: pointer;
                opacity: 1;
            }
        }
    }
}
</style>
